<template>
  <div>
    <div class="ticket">
      <img
        v-if="options[0].OnlineImagePath"
        :src="wsBaseUrl + options[0].OnlineImagePath"
        alt="Logo"
        class="center"
      />
      <p class="centered">
        {{ options[0].CompanyName }} <br />{{ options[0].CompanyAddress }} <br />{{ options[0].CompanyPhone }}
      </p>
      <p style="margin-left: -15px; text-align: left">
        -------------------------------------------------------- <br />
        Nomor Nota : {{ pendingsale[0].SaleNumber }} <br />
        Makan di Tempat <br />
        Kasir : {{ pendingsale[0].CreatedBy }} <br />
        <span v-if="pendingsale[0].CustomerName">Pelanggan : {{ pendingsale[0].CustomerName }} <br /></span>
        Perangkat ke-{{ pendingsale[0].DeviceNo }} <br />
        Tanggal : {{ timesale }} {{ pendingsale[0].SaleTime }} <br />
      </p>
      <table style="margin-top: 20px">
        <div>
          <tbody v-for="(itemDetail, k) in pendingsaleitemdetail" v-bind:key="k">
            <tr>
              <td class="description" colspan="3">
                {{ itemDetail.ItemName }} 
                <span v-if="itemDetail.Discount != ''">
                  - Diskon 
                  <span v-if="itemDetail.Discount.indexOf('%') > 0"> {{ itemDetail.Discount }} </span>
                </span>
                </td>
            </tr>
            <tr>
              <td class="quantity" colspan="2">
                &nbsp;
                {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }} X {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
                <span v-if="itemDetail.Discount != ''"> - {{ $filters.numberTocurrencyIDR((itemDetail.Quantity * itemDetail.UnitPrice) - itemDetail.SubTotal) }}</span>
              </td>
              <td class="subtotal">
                {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
              </td>
            </tr>
            <tr v-for="(isiPaket, kk) in itemDetail.IsiPaket" v-bind:key="kk">
              <td class="quantity" colspan="3">
                &nbsp;&nbsp;&nbsp;&nbsp; >>
                {{ $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity) }} {{ isiPaket.ProductName }} {{ isiPaket.VariantName ? '- '+isiPaket.VariantName : '' }}
              </td>
            </tr>
          </tbody>
        </div>
      </table>

      <table style="margin-top: 20px">
        <tbody>
          <tr>
            <td class="subtotal">
              <span v-if="pendingsalediscount.length > 0 || pendingsale[0].FinalDiscount">
                Total Sebelum Diskon
              </span>
              <span v-else>
                Total
              </span>
            </td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(totalcart) }} </td>
          </tr>
          <tr v-for="(saleDisc, d) in pendingsalediscount" v-bind:key="d">
            <td class="subtotal">{{ saleDisc.DiscountName }} <span v-if="saleDisc.Discount.search('%') > 0">{{saleDisc.Discount}}</span></td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }} </td>
          </tr>
          <tr v-if="pendingsalediscount.length == 0 && pendingsale[0].FinalDiscount">
            <td class="subtotal">Diskon Final <span v-if="pendingsale[0].FinalDiscount.search('%') > 0">{{pendingsale[0].FinalDiscount}}</span></td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(valueDiscountFinal) }} </td>
          </tr>
          <tr v-if="pendingsaletaxexclude.length > 0 && (pendingsalediscount.length > 0 || pendingsale[0].FinalDiscount)">
            <td class="subtotal">Total</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(beforeTax) }} </td>
          </tr>
          <tr v-for="(saleTaxEx, t) in pendingsaletaxexclude" v-bind:key="t">
            <td class="subtotal"> {{ (saleTaxEx.name) }} </td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(saleTaxEx.val) }} </td>
          </tr>
          <tr v-if="pendingsale[0].Rounding">
            <td class="subtotal">Pembulatan</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Rounding) }} </td>
          </tr>
          <tr v-if="(totalcart != (pendingsale[0].Total + (pendingsale[0].Rounding)))">
            <td class="subtotal">Grand Total</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Total + (pendingsale[0].Rounding)) }} </td>
          </tr>
          <tr v-if="paymentModeMsg">
            <td class="subtotal">{{ paymentModeMsg }}</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(payment) }} </td>
          </tr>
          <tr v-if="charge">
            <td class="subtotal">Charge</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(charge) }}% </td>
          </tr>
          <tr v-if="paymentModeMsg2">
            <td class="subtotal">{{ paymentModeMsg2 }}</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(payment2) }} </td>
          </tr>
          <tr v-if="pendingsale[0].Change">
            <td class="subtotal">Kembali</td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Change) }} </td>
          </tr>
          <tr v-if="pendingsaletaxinclude.length > 0">
            <td class="subtotal">Sudah Termasuk</td>
            <td class="quantity"> : </td>
            <td class="subtotal"></td>
          </tr>
          <tr v-for="(saleTaxIn, t) in pendingsaletaxinclude" v-bind:key="t">
            <td class="subtotal"> {{ (saleTaxIn.name) }} </td>
            <td class="quantity"> : </td>
            <td class="subtotal"> {{ $filters.numberTocurrencyIDR(saleTaxIn.val) }} </td>
          </tr>
        </tbody>
      </table>

      <p class="centered">
        <span v-if="options[0].FootNoteSatu"> {{ options[0].FootNoteSatu }} <br /> </span>
        <span v-if="options[0].FootNoteDua"> {{ options[0].FootNoteDua }} <br /> </span>
        <span v-if="options[0].FootNoteTiga"> {{ options[0].FootNoteTiga }} <br /> </span>
        <span v-if="options[0].FootNoteEmpat"> {{ options[0].FootNoteEmpat }} <br /> </span>
        powered by nutapos.com
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ //disabled eslint compiler
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "PrintReceipt",
  components: {},
  data: function () {
    return {
      totalcart : 0,
      timesale: null,
      paymentModeMsg: null,
      paymentModeMsg2: null,
      payment: 0,
      payment2: 0,
      pendingsale: [{}],
      pendingsalediscount: [],
      pendingsaletaxexclude: [],
      pendingsaletaxinclude: [],
      pendingsaleitemdetail: [],
    };
  },
  methods: {
    printPage() {
      this.dataReceipt();
      window.print();
      setTimeout(() => window.close(), 2000);
    },
    dataReceipt() {
      // get data sale
      var sale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
      var saleData = sale.filter((item) => {
        return (item.TransactionID + "." + item.DeviceNo == this.$route.query.TransactionID);
      });
      // get data sale discount
      var saleDiscount = localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [];
      var saleDiscountData = saleDiscount.filter((disc) => {
        return (disc.TransactionID + "." + disc.TransactionDeviceNo == this.$route.query.TransactionID);
      });
      // get data sale item detail
      var saleItemDetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
      var saleItemDetailData = saleItemDetail.filter((items) => {
        return (items.TransactionID + "." + items.TransactionDeviceNo == this.$route.query.TransactionID);
      });
      // get data sale item detail product(isi paket)
      var saleItemProduct = localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [];
      for(var d=0; saleItemDetailData.length > d; d++){
          //var detailarr = [];
          this.totalcart += parseFloat(saleItemDetailData[d].SubTotal);

          var saleItemProductData = saleItemProduct.filter((itemsprod) => {
            return (itemsprod.TransactionID + "." + itemsprod.TransactionDeviceNo == this.$route.query.TransactionID && itemsprod.DetailID == saleItemDetailData[d].DetailID && itemsprod.DetailDeviceNo == saleItemDetailData[d].DeviceNo);
          });
          saleItemDetailData[d].IsiPaket = saleItemProductData;
      }

      var taxex = saleData[0].DetailExcludeTaxValues.split("#");
      for(var i=0; taxex.length > i; i++){
          if(taxex[i]){
              var taxexarr = {
                  name: taxex[i].split("$")[0],
                  val: taxex[i].split("$")[1],
              };
              this.pendingsaletaxexclude.push(taxexarr);
          }
      }

      var taxin = saleData[0].DetailIncludeTaxValues.split("#");
      for(var tin=0; taxin.length > tin; tin++){
          if(taxin[tin]){
              var taxinarr = {
                  name: taxin[tin].split("$")[0],
                  val: taxin[tin].split("$")[1],
              };
              this.pendingsaletaxinclude.push(taxinarr);
          }
      }

      // metode pembayaran
      if(saleData.length > 0){
        let tempTr = saleData[0];
        var paymentMode = tempTr.PaymentMode.toString();
        if(paymentMode.substring(0, 1) == "1"){
          this.paymentModeMsg = "Bayar Tunai"
          this.payment = saleData[0].CashPaymentAmount
        } else if(paymentMode.substring(0, 1) == "2"){
          this.payment = saleData[0].BankPaymentAmount
          if(paymentMode.substring(0, 2) == "21"){
            this.paymentModeMsg = "Bayar Dompet Digital"
          } else if(paymentMode == "22"){
            this.paymentModeMsg = "Bayar EDC"
          } else if(paymentMode == "23"){
            this.paymentModeMsg = "Bayar Transfer"
          } else if(paymentMode == "24"){
            this.paymentModeMsg = "Bayar QR Statis"
          }
        } else if(paymentMode.substring(0, 1) == "3"){
          this.payment = saleData[0].CashPaymentAmount
          this.payment2 = saleData[0].BankPaymentAmount
          this.paymentModeMsg = "Bayar Tunai"
          if(paymentMode.substring(0, 2) == "31"){
            this.paymentModeMsg2 = "Bayar Dompet Digital"
          } else if(paymentMode == "32"){
            this.paymentModeMsg2 = "Bayar EDC"
          } else if(paymentMode == "33"){
            this.paymentModeMsg2 = "Bayar Transfer"
          } else if(paymentMode == "34"){
            this.paymentModeMsg2 = "Bayar QR Statis"
          }
        }
      }

      this.timesale = this.convertDateDBtoIndo(saleData[0].SaleDate);
      this.pendingsale = saleData;
      this.pendingsalediscount = saleDiscountData;
      this.pendingsaleitemdetail = saleItemDetailData;
    },
    convertDateDBtoIndo(string) {
        var bulanIndo = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep' , 'Okt', 'Nov', 'Des'];
    
        var tanggal = string.split("-")[2];
        var bulan = string.split("-")[1];
        var tahun = string.split("-")[0];
    
        return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    }
  },
  computed: {
    ...mapGetters(["options", "wsBaseUrl"]),
    //diskon final
    valueDiscountFinal() {
      let discFinal = 0;
      if(this.pendingsalediscount.length == 0){
        if (this.pendingsale[0].FinalDiscount.search('%') > 0) {
          discFinal = (this.totalcart / 100) * this.pendingsale[0].FinalDiscount.replace("%", "");
        } else {
          discFinal = this.pendingsale[0].FinalDiscount;
        }
      }
      return discFinal;
    },
    //total sebelum pajak exclude
    beforeTax() {
      let beforeTax = 0;
      var tot = this.totalcart
      if(this.pendingsalediscount.length > 0){
        this.pendingsalediscount.forEach(function (discOutlet) {
          if(discOutlet.Discount.search("%") > 0){
            tot = parseFloat(tot - ((tot / 100)* discOutlet.Discount.replace("%", "")));
          } else {
            tot = parseFloat(tot - discOutlet.Discount);
          }
        });
      }
      beforeTax = tot - this.valueDiscountFinal;
      return beforeTax;
    },
    //perhitungan charge
    charge(){
      var charge = 0
      var a = (parseFloat(this.pendingsale[0].CashDownPayment) + parseFloat(this.pendingsale[0].BankDownPayment) + parseFloat(this.pendingsale[0].CashPaymentAmount))
      var b = parseFloat((this.pendingsale[0].Total + this.pendingsale[0].Rounding)) - a;
      var c = parseFloat(this.pendingsale[0].BankPaymentAmount) - (parseFloat((this.pendingsale[0].Total + this.pendingsale[0].Rounding)) - a);
      var d = (c / b) * 100;
      if(d > 0 && b > 0) {
        charge = d
      } else {
        charge = 0
      }
      return charge
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.printPage();
  },
};
</script>
<style scoped>
td,
th,
tr,
table {
  /*border: 1px solid black;*/
  border-collapse: collapse;
  width: 100%;
}

td.description {
  text-align: left;
}

td.quantity {
  text-align: left;
}

td.subtotal {
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 280px;
  max-width: 280px;
}

img {
  max-width: inherit;
  width: inherit;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 110px;
  width: auto;
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
</style>